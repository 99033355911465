/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/** 
작성자: 임호균
작성일: 2023-08-17
내용: 사이드 네비게이션 공통 스타일 지정, 툴팁 공통 스타일 지정

수정일: 2023-08-18
수정자: 임호균
내용: 사이드 네비게시연 공통 스타일 수정. 
side_nav_toolbar
*/
$side_nav_active_color: #eeeeee;
// $side_nav_active_font_color: rgba(255, 64, 129, 255);
$side_nav_active_font_color: rgba(63, 81, 181, 255);
$smart_service_background_color: rgba(250, 250, 250, 255);
$smart_service_primary_color: rgba(63, 81, 181, 255);

.side_nav {
  width: 278px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

/**
작성자: 임호균
*/
.side_nav_toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;

  h1 {
    margin: 0;
  }
}

.side_nav_items {
  flex: 1;
  position: relative;
}

.scroll {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll::-webkit-scrollbar {
  display: none;
}

.toolbar {
  display: flex;
  align-items: center;
}

/**
작성자: 임호균
작성일: 2023-08-28
설멍: 공통 search, table css 설정
*/
.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_title {
  padding: 0;
  margin: 0 !important;
  text-decoration: none;
}

.search_section {
  margin-top: 20px;
}

.table_section {
  margin-top: 50px;
}

.input_group_layout {
  display: flex;

  @media (max-width: 816px) {
    flex-direction: column;

    .left_box {
      width: 100% !important;
      padding: 0px !important;
      box-sizing: border-box;
      background-color: #fff !important;
      color: #000 !important;
    }

    .right_box {
      border-bottom: none !important;
      padding: 10px 0px !important;
    }
  }

  .left_box {
    display: flex;
    background-color: $smart_service_primary_color;
    color: #fff;
    padding: 20px;
    font-weight: 700;
    width: 200px;
    margin: 1px;
    align-items: center;
    border-radius: 4px;
  }

  .no_border_bottom {
    border-bottom: none !important;
  }

  .right_box {
    display: flex;
    flex: 1;
    padding: 20px;
    gap: 10px;
    align-items: center;
    border-bottom: solid 1px rgb(224, 224, 224);
  }
}
